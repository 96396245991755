.ladder-body {
    margin: 20px auto;
}

.ladder-box {
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.568);
    margin: auto;
    margin-top: 100px;
    margin-bottom: 50px;
    transition: all 1s ease-in-out;
    display: inline-block;
    padding: 20px;
    width: 600px;
    height: fit-content;
    text-align: center;
    position: relative;
}
.ladder-text {
    text-align: center;
}

table, th, td {
    border: 1px solid;
    padding:5px;
}

table {
    margin: auto;
    border-collapse: collapse;
    width: 350px;
}

.ladder-box button:focus {
    margin: 0 1px;
    transition: all 0.15s linear;
    color: white;
    background-color: black;
    border: inset white;
    border-width: 1px;
    border-radius: 5px;
}


th:hover {
    cursor:pointer;
}

@media (max-width: 1000px) {
    .ladder-box {
        width: 450px;
        font-size: 0.8em;

    }
    table {
        width: 250px;
    }
}