.gacha-box {
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.568);
    margin: auto;
    margin-top: 120px;
    margin-bottom: 50px;
    transition: all 1s ease-in-out;
    display: inline-block;
    padding: 20px;
    width: 500px;
    height: fit-content;
    text-align: center;
    position: relative;
}

.merchant-header {
    width: 75px;
    margin: 0 3px;
}

.merchant-header-select {
    width: 75px;
    background-color: black;
    color: white;
    border-radius: 2px;
    border: white solid;
    border-width: 1px;
    margin: 0 3px;
}


.gacha-text {
    text-align: center;
}

.gacha-img-container {
    display: inline-flex;
    margin: 10px;
}

.gacha-img-container button {
    margin: auto;
    height: 23px;
    transition: 0.2s;
}

.gacha-img-container button:hover {
    transition: 0.1s;
    background-color: black;
    color: white;
}

.gacha-img-box{
    margin: auto;
    display: flex;
    position:relative;
    width:200px;
}

.gacha-img-box img {
    border-radius: 10px;
    width: inherit;
    position: absolute;
    backface-visibility: hidden;
    transition: 1s;
}

.gacha-img-box div {
    box-shadow: inset 0 0px 15px white, 0 0px 5px white;
    border-radius: 10px;
    border: solid rgba(255, 255, 255, 0.76) 2px;
    width: 200px;
    height: 200px;
    transition: 1s;
    backface-visibility: hidden;
}

.gacha-img-box div p {
    padding-top: 45%;
    margin: auto;
}

.gacha-img-box:hover img:nth-child(1) {
    transform: rotateY(-180deg);
}
.gacha-img-box img:nth-child(1) {
    transform: rotateY(0deg);
}
.gacha-img-box div:nth-child(2) {
    transform: rotateY(180deg);
}
.gacha-img-box:hover div:nth-child(2) {
    transform: rotateY(0deg);
}

.gacha-button {
}

.merchant-img {
    border-radius: 35%;
    width: 500px;
}

.merchant-text {
    color:rgba(255, 220, 191, 0.809);
    font-style: italic;
    font-family: 'Courier New', Courier, monospace;
    transition: 0.3s;

}

.merchant-text:hover {
    transition: 0.3s;
    color:rgb(255, 181, 120);
    cursor: pointer;
}

.gacha-footer {
    position: absolute;
    right: 20px;
    bottom: 1px;
}

.gacha-footer p {
    opacity: 60%;
    display: inline-block;
    font-size: 0.7em;
}

.gacha-footer img {
    cursor: pointer;
    margin-left: 5px;
    display: inline-block;
    width: 75px;
    transition: 300ms ease-out;
}

.gacha-footer img:hover {
    filter: drop-shadow(0 0 5px #ff0000be);
    transition: 200ms ease-out;
}

.common {

}

.uncommon {
    animation: uncommonGlow 750ms infinite ease-in-out;
    color:rgb(140, 255, 129)
}

.rare {
    animation: rareGlow 1000ms infinite ease-in-out;
    color: #ffbefa
}

.legendary {
    animation: legendaryGlow 1000ms infinite ease-in-out;
    border-radius:10px;
    border: solid rgba(255, 255, 255, 0.441) 1px;
    width: fit-content;
    padding: 5px;
    margin: auto;
}

@keyframes rareGlow {
    0% {
        text-shadow: 0 0 7px rgb(239, 114, 255);
        color:#f233e2
    }
    50% {
        text-shadow: 0 0 10px rgb(255, 20, 235);
        color: white
    }
    100% {
        text-shadow: 0 0 7px rgb(239, 114, 255);
        color:#f233e2
    }
}

@keyframes uncommonGlow {
    0% {
        text-shadow: 0 0 7px rgb(21, 255, 0);
    }
    50% {
        text-shadow: 0 0 15px rgb(8, 160, 0);
    }
    100% {
        text-shadow: 0 0 7px rgb(21, 255, 0);
    }
}

@keyframes legendaryGlow {
    0% {
        text-shadow: 0 0 20px rgb(255, 203, 114);
        box-shadow: 0 0 10px gold, 0 0 10px inset gold;
        color:#ffb908
    }
    50% {
        text-shadow: 0 0 10px rgb(255, 0, 0);
        box-shadow: 0 0 10px gold, 0 0 10px inset gold;
        color: white
    }
    100% {
        text-shadow: 0 0 20px rgb(255, 203, 114);
        box-shadow: 0 0 10px gold, 0 0 10px inset gold;
        color:#ffb908
    }
}

@media (max-width: 1000px) {
    .merchant-header {
        font-size: 0.7em;
    }
    .merchant-header-select {
        font-size: 0.7em;
    }

    .gacha-box {
        width: 300px;
    }
    .merchant-img {
        width: 300px;
        object-fit: cover;
    }
    .gacha-img-box{
        width: 150px;
    }
    .gacha-img-box div {
        width: 150px;
        height: 150px;
        font-size: 12px;
    }
    
    .gacha-footer p {
        font-size: 0.5em;
    }
    
    .gacha-footer img {
        width: 50px;
    }
    
}

.item-show p {
    cursor: pointer;
}

.item-title {
    animation: tGlow 1000ms ease-in-out infinite;
    cursor: pointer;
    text-align: center;
}

.cursor-gacha {
    transform: translate(300px, -75px);
    box-shadow: 0 0 10px white;
    position: absolute;
    width: 100px;
    height: 100px;
    border: 2px solid white;
    border-radius: 25%;
    box-sizing: border-box;
    transition: 0.1s;
    overflow: hidden;
    animation: glow 1000ms ease-in-out infinite, scaleGacha 1000ms 1;

}

@keyframes tGlow {
    0% {
        text-shadow: 0 0 20px rgba(255, 255, 255, 0.808);
    }
    25% {
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.808);
    }
    50% {
        text-shadow: 0 0 3px rgba(255, 255, 255, 0.507);
    }
    100% {
        text-shadow: 0 0 20px rgba(255, 255, 255, 0.808);
    } 
}

@keyframes glow {
    0% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.808);
    }
    25% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.808);
    }
    50% {
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.507);
    }
    100% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.808);
    }
}

@keyframes scaleGacha {
    0% {
        
        opacity: 0%;
        transform: translate(300px, -75px) scale(0%);
        border-radius: 100%;
    }
    10% {
        opacity: 70%;
    }
    100% {
        opacity: 100%;
        transform: translate(300px, -75px) scale(100%);
        border-style: inset;
        
    }
}

.cursor-gacha img {
    position: absolute;
    width: 96px;
    transform: translate(-48px, 0px);
}

