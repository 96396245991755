
@import url(https://fonts.googleapis.com/css?family=Cabin:400);

* {
    font-family: 'Cabin', helvetica, arial, sans-serif;
    color: white;
}

h3 {
    font-weight: normal;
}

body {
    height: 100%;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}

.special-button {
    width: 120px;
    height: 30px;
    display: block;
    margin: auto;
    border-radius: 100px;
    border: rgba(198, 17, 198, 0.789) solid;
    box-shadow: 0 0 10px purple;
    border-width: 3px;
    background-color: black;
    color: rgb(239, 156, 255);
    text-shadow: 0 0 5px rgba(255, 127, 223, 0.33);
    transition: 0.5 all ease-in-out;
}

.special-button-disabled {
    width: 120px;
    height: 30px;
    display: block;
    margin: auto;
    border-radius: 100px;
    border: rgba(132, 132, 132, 0.789) solid;
    border-width: 3px;
    background-color: black;
    color: rgb(174, 162, 176);
    text-shadow: 0 0 5px rgba(255, 127, 223, 0.33);
    transition: 0.5 all ease-in-out;
}

.special-button:hover {
    transform:scale(1.1) ; /* Move the button down slightly when active */
    transition: all 0.2s ease-out;
    font-weight: bold;
    cursor: pointer;
}

.special-button-pressed {
    width: 175px;
    height: 30px;
    display: block;
    margin: auto;
    border-radius: 100px;
    border: rgba(255, 255, 255, 0.789) solid;
    box-shadow: 0 0 15px rgb(255, 169, 255);
    border-width: 3px;
    background-color: rgb(83, 0, 47);
    color: white;
    text-shadow: 0 0 6px rgba(255, 255, 255, 0.938);
    transition: 0.5 all ease-in-out;
    cursor: pointer
}


.deposit-input {
    text-align: center;
    width: 100px;
    border-radius: 100px;
    font-size: 1.5em;
}

#str-input {
    animation: hGlowSTR infinite ease-in-out alternate 1000ms;

}
#dex-input {
    animation: hGlowDEX infinite ease-in-out alternate 1000ms;

}
#int-input {
    animation: hGlowINT infinite ease-in-out alternate 1000ms;

}
#all-input {
    animation: hGlowNUL infinite ease-in-out alternate 1000ms;

}
#nul-input {
    animation: hGlowNUL infinite ease-in-out alternate 1000ms;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


.deposit-button {
    width: 100px;
    height: 50px;
    font-size: 1.4em;
    border-radius: 100px;
}



.center-element {
    text-align: center;
}

.clickable:hover {
    text-decoration: underline;
    cursor: pointer;
}

button {
    color: black;
}

input {
    background-color: black;
    color: white;
}

.input-buttons {
    margin: 20px 30px;
    width: 100px;
    height: 30px;
}

.checkmark {
    text-decoration: underline;
}

.attribute-lvl {
    text-shadow: 0 0 15px rgba(255, 255, 255, 0.521), 0 0 20px #ffffff;
    font-weight: bold;
    transition: all 0.3s linear;
}

.attribute-str {
    text-shadow: 0 0 15px red, 0 0 20px #e60073;
    font-weight: bold;
    transition: all 0.3s linear;
}
.attribute-dex {
    text-shadow: 0 0 15px #01bd55, 0 0 20px #00ff7388;
    font-weight: bold;
    transition: all 0.3s linear;
}
.attribute-int {
    text-shadow: 0 0 15px #00b5c2, 0 0 20px #00eeff7c;
    font-weight: bold;
    transition: all 0.3s linear;
}

.box {
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.568);
    margin-left: auto;
    margin-right: auto;
    margin-top: 100px;
    margin-bottom: 50px;
    padding: 10px;
    width: fit-content;
    height: fit-content;
    transition: all 1s ease-in-out;
    display: inline-block;
    border-width: 3px;
}

.box-when-fighting-boss {
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.568);
    margin-left: auto;
    margin-right: 5%;
    margin-top: 100px;
    margin-bottom: 50px;
    padding: 10px;
    width: fit-content;
    height: fit-content;
    transition: all 1s ease-in-out;
    display: inline-block;
    border-width: 3px;
}

.box-boss {
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.568);
    margin-left: 5%;
    margin-right: auto;
    margin-top: 100px;
    padding: 15px;
    width: fit-content;
    height: fit-content;
    transition: all 1s ease-in-out;
    display: inline-block;
    animation: 1s ease-in-out 0s 1 scale-up;
}

.boss-content {
    animation: 2s linear 0s 1 appear;
}

.boss-image {
    /* border: solid white 1px; */
    transition: 1s ease-in-out;
    animation: hGlowNUL 1500ms ease-in-out infinite alternate;
    display: block;
    margin: auto;
    border-radius: 50%;
    width: 300px;
    height: 300px;
}

.boss-image:hover {
    width: 320px;
    height: 320px;
    transition: 1.5s ease-in-out;
}

.glow-around {
    position: relative;
    border-radius: 50%;
}

@keyframes glowAround {
    0% {
        transform: rotate(0deg);
        filter: hue-rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        filter: hue-rotate(360deg);
    }
}

.glow-around:before {
    content: "";
    position: absolute;
    top: -6px;
    left: -6px;
    right: -6px;
    bottom: -6px;
    background: linear-gradient(45deg, transparent, transparent 40%, #e5f);
    border-radius: 50%;
    z-index: -2;
    animation: glowAround 1.5s linear infinite;

}

.glow-around:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    background: linear-gradient(45deg, transparent, transparent 40%, #e5f);
    border-radius: 50%;
    z-index: -1;
    filter: blur(30px);
    animation: glowAround 1.5s linear infinite;
}

@keyframes scale-up {
    0% {
        transform:scaleY(0);
        border-width: 50px 2px;
    }
    100% {
        transform:scaleY(100%);
        border-width: 3px;
    }
}

@keyframes appear {
    0% {
        opacity: 0%;
        }
    40% {
        opacity: 0%;
        }
    100% {
        opacity: 100%;
        }
}

#nul-box {
    animation: hGlowNUL 1500ms ease-in-out infinite alternate, 1s ease-in-out 0s 1 scale-up;
    ;
    transition: all 1s linear;
    
    
}

#str-box {
    animation: hGlowSTR 1500ms ease-in-out infinite alternate;
    transition: all 1s linear;
}

#int-box {
    animation: hGlowINT 1500ms ease-in-out infinite alternate;
    transition: all 1s linear;
}

#dex-box {
    animation: hGlowDEX 1500ms ease-in-out infinite alternate;
    transition: all 1s linear;
}

#all-box {
    animation: hGlowALL 750ms ease-in-out infinite alternate;
    transition: all 1s linear;
}

@keyframes hGlowNUL {
    0% {
		border-color:rgb(255, 255, 255);
		box-shadow: 0 0 5px rgb(255, 255, 255), inset 0 0 20px rgba(255, 255, 255, 0.582), 0 0 2px rgba(255, 255, 255, 0.267);
    }	
    100% {
		border-color: rgb(179, 179, 179);
		box-shadow: 0 0 25px rgba(255, 255, 255, 0.267), inset 0 0 10px rgba(255, 255, 255, 0.562), 0 0 2px rgb(255, 255, 255);
    }
}

@keyframes hGlowSTR {
    0% {
		border-color:rgb(204, 0, 34);
		box-shadow: 0 0 5px rgb(95, 40, 40), inset 0 0 20px rgba(77, 3, 19, 0.726), 0 0 3px rgb(255, 0, 55);
    }	
    100% {
		border-color: rgb(255, 0, 98);
		box-shadow: 0 0 50px rgba(255, 0, 34, 0.664), inset 0 0 10px rgba(255, 0, 43, 0.596), 0 0 2px rgb(253, 0, 55);
    }
}
@keyframes hGlowINT {
    0% {
		border-color:rgb(0, 195, 255);
		box-shadow: 0 0 5px rgb(0, 119, 255), inset 0 0 5px rgba(0, 87, 201, 0.829), 0 0 3px rgb(0, 162, 255);
    }	
    100% {
		border-color: rgb(0, 132, 255);
		box-shadow: 0 0 50px rgba(0, 110, 255, 0.596), inset 0 0 10px rgba(0, 173, 253, 0.24), 0 0 3px rgb(1, 217, 255);
    }
}
@keyframes hGlowDEX {
    0% {
		border-color:rgb(0, 255, 42);
		box-shadow: 0 0 5px rgb(0, 161, 8), inset 0 0 5px rgba(0, 204, 0, 0.637), 0 0 4px rgb(0, 255, 0);
    }	
    100% {
		border-color: rgb(1, 192, 43);
		box-shadow: 0 0 50px rgba(0, 255, 0, 0.61), inset 0 0 10px rgba(0, 255, 64, 0.712), 0 0 2px rgb(0, 255, 85);
    }
}

@keyframes hGlowALL {
    0% {
		border-color:rgb(175, 0, 117);
		box-shadow: 0 0 5px rgb(209, 0, 161), inset 0 0 5px rgba(251, 0, 255, 0.637), 0 0 4px rgb(183, 0, 149);
    }	
    100% {
		border-color: rgb(103, 0, 200);
		box-shadow: 0 0 50px rgba(187, 0, 255, 0.61), inset 0 0 10px rgba(174, 0, 255, 0.712), 0 0 2px rgb(162, 0, 255);
    }
}

.box-title {
    text-align: center;
}
@keyframes tGlow {
    0% {
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.486), 0 0 20px #e60073;
    }	
    50% {
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.527), 0 0 20px #0059ff;
    }
    100% {
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.527), 0 0 20px #00ff0d85;
    }
}

.stats-section p:hover {
    cursor: pointer;
    text-decoration: underline;
}

#exp {
    font-size: 10px;
}