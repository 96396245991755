html, body {
  height: 100%;
}

.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  min-width: 300px;
  min-height: 60px;
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  text-transform: uppercase;
  letter-spacing: 1.3px;
  font-weight: 700;
  color: #313133;
  background: #ffce46;
background: linear-gradient(90deg, rgb(255, 223, 82) 0%, rgb(255, 193, 23) 100%);
  border: none;
  border-radius: 1000px;
  box-shadow: 12px 12px 24px rgba(65, 43, 3, 0.64);
  transition: all 0.3s ease-in-out 0s;
  cursor: pointer;
  outline: none;
  position: relative;
  padding: 10px;
  }

#splashButton::before {
content: '';
  border-radius: 1000px;
  min-width: calc(300px + 12px);
  min-height: calc(60px + 12px);
  border: 6px solid #ffd752c2;
  box-shadow: 0 0 60px rgba(255, 174, 0, 0.712);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: all .3s ease-in-out 0s;
}

.button:hover, .button:focus {
  color: #313133;
  transform: translateY(-6px);
}

#splashButton:hover::before, button:focus::before {
  opacity: 1;
}

#splashButton::after {
  content: '';
  width: 30px; height: 30px;
  border-radius: 100%;
  border: 6px solid #ffdc8f67;
  position: absolute;
  z-index: -1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: ring 1.5s infinite;
}

#splashButton:hover::after, button:focus::after {
  animation: none;
  display: none;
}

@keyframes ring {
  0% {
    width: 30px;
    height: 30px;
    opacity: 1;
  }
  100% {
    width: 300px;
    height: 300px;
    opacity: 0;
  }
}

@media (max-width: 1000px) {
  .button {
    min-width: 180px;
    min-height: 50px;
    font-size: 14px;
    box-shadow: 6px 6px 12px rgba(209, 168, 79, 0.64);
  }
  #splashButton::after {
    width: 5px;
    height: 5px;
  }


}