:root {
    --width: 220px;
}

@media (max-width: 1000px) {  
}

.header {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.466);
    padding: 10px 10px;
    border-radius: 30px;
    border-width: 3px;
    border-color: rgba(255, 255, 255, 0.89);
    border-style: solid;
    width: var(--width);
    left: 50%;
    margin-left: calc(var(--width) / -1.77);
    text-align: center;
    transition: all 1s linear;
    animation: 0.5s ease-in-out 0s 1 slideInFromLeft;
    z-index: 1;
}

.rotate-img {
  transform:rotate(180deg)
}

@keyframes slideInFromLeft {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
.header-button {
    margin: 0 3px;
    padding: 2px;
    transition: all 0.15s linear;
    width: 98px;
}

#main {
  background-color: rgb(253, 212, 76);
  font-weight: bold;
  width: 150px;
  border: inset;
  border-color: goldenrod;
  border-radius: 5px;
}

#main-picked {
  transition: all 0.15s linear;
  color: white;
  width: 150px;
  background-color: black;
  border: inset white;
  border-radius: 5px;
}


  /* Add an active effect */
  .header-button:active {
    transform:scale(1.1) ; /* Move the button down slightly when active */
    transition: all 0.05s linear;
  }

  .button-picked {
    margin: 0 3px;
    padding: 2px;
    transition: all 0.15s linear;
    color: white;
    width: 98px;
    background-color: black;
    border: inset white;
    border-width: 1px;
    border-radius: 5px;
  }

  .button-picked:active {
    transform:scale(1.1) ; /* Move the button down slightly when active */
    transition: all 0.05s linear;
  }