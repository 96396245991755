.item-title {
    animation: tGlow 1000ms ease-in-out infinite;
    cursor: pointer;
    text-align: center;
}

.cursor {
    transform: translate(250px, -75px);
    box-shadow: 0 0 10px white;
    position: absolute;
    width: 100px;
    height: 100px;
    border: 2px solid white;
    border-radius: 25%;
    box-sizing: border-box;
    transition: 0.1s;
    overflow: hidden;
    animation: glow 1000ms ease-in-out infinite, scale 1000ms 1;

}

@keyframes tGlow {
    0% {
        text-shadow: 0 0 20px rgba(255, 255, 255, 0.808);
    }
    25% {
        text-shadow: 0 0 10px rgba(255, 255, 255, 0.808);
    }
    50% {
        text-shadow: 0 0 3px rgba(255, 255, 255, 0.507);
    }
    100% {
        text-shadow: 0 0 20px rgba(255, 255, 255, 0.808);
    } 
}

@keyframes glow {
    0% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.808);
    }
    25% {
        box-shadow: 0 0 10px rgba(255, 255, 255, 0.808);
    }
    50% {
        box-shadow: 0 0 3px rgba(255, 255, 255, 0.507);
    }
    100% {
        box-shadow: 0 0 20px rgba(255, 255, 255, 0.808);
    }
}

@keyframes scale {
    0% {
        
        opacity: 0%;
        transform: translate(250px, -75px) scale(0%);
        border-radius: 100%;
    }
    10% {
        opacity: 70%;
    }
    100% {
        opacity: 100%;
        transform: translate(250px, -75px) scale(100%);
        border-style: inset;
        
    }
}

.cursor img {
    position: absolute;
    width: 96px;
}

