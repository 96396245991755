.stats-box {
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.568);
    margin: auto;
    margin-top: 120px;
    margin-bottom: 50px;
    transition: all 1s ease-in-out;
    display: inline-block;
    padding: 20px;
    width: 85%;
    height: 700px;
    text-align: center;
}

.stats-container {
    display: flex;
    justify-content: center;
}

.personal-stats {
    border: solid white;
    border-radius: 50px 0 0 50px;
    border-width: 1px 0px 1px 1px;
    width: 40%;
    height: 350px;
    text-align: end;
    padding: 10px
}

.personal-stats h3 {
    text-align: center;
}

.world-stats {
    border: solid white;
    border-radius: 0 50px 50px 0;
    border-width: 1px;
    width: 40%;
    padding: 10px
}

.world-stats p {
    text-align: left;
}

@media (max-width: 1000px) {
    p {
        font-size: 0.7em;
    }
}