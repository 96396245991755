img {
}

.inventory-box {
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.568);
    margin: auto;
    margin-top: 120px;
    margin-bottom: 50px;
    transition: all 1s ease-in-out;
    display: inline-block;
    padding: 20px;
    width: 1300px;
    height: fit-content;
    text-align: center;
}

.inventory-title{
    display: block;
}

.item-image {
    width: 50px;
}

.inventory-container {
    display: flex;
}

.item-container {
    font-size: 0.7em;
    width: 75px;
    height: 125px;
    background-color: black;
    padding: 10px;
    text-align: center;
    border: 2px solid;
    margin: 5px;
    border-radius: 10px;
    transition: 0.3s ease-in-out;

}

.item-container img {
    box-shadow: 0 0 5px  rgba(255, 255, 255, 0.591);
    border-radius: 5px;    
}

.item-container:hover {
    box-shadow: inset 0 10px 30px rgba(255, 255, 255, 0.61);
    transition: 0.3s ease-out;
    background-color: rgba(0, 21, 53, 0.137);
    cursor: pointer;
}

.item-equipped{
    font-size: 0.7em;
    width: 75px;
    height: 125px;
    padding: 10px;
    text-align: center;
    border: 2px solid;
    margin: 5px;
    border-radius: 10px;
    box-shadow: inset 0 10px 30px rgba(0, 0, 0, 0.649);
    transition: 0.3s ease-out;
    background-color: rgb(0, 86, 143);
}

.item-equipped img {
    box-shadow: 0 0 5px  rgba(255, 255, 255, 0.591);
    border-radius: 5px;     
}

.item-equipped:hover {
    box-shadow: inset 0 10px 30px rgba(255, 255, 255, 0.61);
    transition: 0.3s ease-out;
    background-color: rgb(0, 86, 143);
    cursor: pointer;
}
#trade-item-equipped {
    background-color: rgb(59, 59, 59);
}
#trade-item-equipped:hover {
    box-shadow: inset 0 10px 30px rgba(0, 0, 0, 0.61);
}
#trade-item-equipped p {
    color: black
}

#item-selected {
    box-shadow: inset 0 10px 30px rgba(255, 255, 255, 0.61);
    transition: 0.3s ease-out;
}

#item-selected:hover {
    box-shadow: inset 0 10px 30px rgb(255, 255, 255);
    transition: 0.3s ease-out;
}

.items-container {
    display: flex;
    flex-wrap: wrap;
    width: 50%;
    border: 2px solid white;
    border-radius: 10px;
    padding: 5px;
    height: 500px;
    overflow-y: auto;
    position: relative;
}

.display-item {
    text-align: center;
    width: 50%;
    border: 2px solid white;
    border-radius: 10px;
    padding: 5px;
    height: 500px;
}

.display-item p {
    font-size: 0.8em;
}

.display-image {
    width: 200px;
    height: 200px;
    box-shadow: 0 0 20px rgba(255, 255, 255, 0.607);
    border-radius: 5px;
}

.description-text {
    color:rgba(212, 172, 139, 0.753);
    font-style: italic;
    font-size:0.8em;
    font-family: 'Courier New', Courier, monospace;
    transition: 0.3s;

}

.select-filter {
    color: black;
    height: 20px;
    width: 75px;
    position: absolute;
    right: 2px;
}
.select-filter option {
    color: black;
}

@media (max-width: 1000px) {
    .item-image {
        width: 40px;
    }
    .item-container {
        width: 45px;
        height: 90px;
        font-size: 11px;
        margin: 1px;
    }
    .display-image {
        width: 120px;
        height: 120px;
    }
    .display-item {
        font-size: 11px;
    }

    .item-equipped {
        width: 45px;
        height: 90px;
        font-size: 11px;
        margin: 1px;
    }

}