.trade-items-container {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 80%;
    border-bottom: solid 1px white;
    padding: 5px;
    height: 300px;
    overflow-y: auto;
    position: relative;
}

.trade-with-items-container {
    display: flex;
    flex-wrap: wrap;
    margin: auto;
    width: 80%;
    border-bottom: solid 1px white;
    border-top: solid 1px white;
    padding: 5px;
    height: 300px;
    overflow-y: auto;
    position: relative;
}

.trade-box {
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.568);
    margin: auto;
    margin-top: 120px;
    margin-bottom: 50px;
    transition: all 1s ease-in-out;
    display: inline-block;
    padding: 10px;
    width: 700px;
    height: fit-content;
    text-align: center;
    position: relative;
}

.create-trade-box {
    position: relative;
    text-align: center;
    z-index: 3;
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: 50px;
    padding: 10px;
    padding-bottom: 33px;
    width: 500px;
    height: 500px;
    transition: all 1s ease-in-out;
    display: inline-block;
    border-width: 3px;
    animation: 1.5s ease-in-out 0s 1 warnScale;
    opacity: 100%;
    
}

.create-trade-with-box {
    position: relative;
    text-align: center;
    z-index: 3;
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: 50px;
    padding: 10px;
    padding-bottom: 33px;
    width: 800px;
    height: fit-content;
    transition: all 1s ease-in-out;
    display: inline-block;
    border-width: 3px;
    animation: 1.5s ease-in-out 0s 1 warnScale;
    opacity: 100%;
    
}

.item-name {
    line-height: 8px;
    transition: all linear 0.1s;
    color: rgb(255, 228, 139)

}
.item-name:hover {
    color:red;
    cursor: pointer;
    transition: all linear 0.2s;
}

.items-table {
    font-size: 0.8em;
    width: 500px;
}
.items-table td {
    padding: 0 5px;
}

@media (max-width:1000px) {
    .create-trade-box {
        width: 300px;
    }    
    .create-trade-with-box {
        width: 300px;
    }
    .items-table {
        width: 350px;
    }
}