.members-list {
    /* text-align: left; */
    padding: 0 80px 0 80px;
    flex-wrap: wrap;
    display: flex;
}

.members-list .names {
}

.members-list .buttons {
    position: absolute;
    margin-left: 225px;
}

.choose-hall button{
    margin: 0 10px 0 10px;
}

.choose-hall img {
    width: 350px;
}

.create-guild-button {
    height: 35px;
    border-radius: 50px;
    font-size: 1em;
    padding: 5px 15px;
    color: white;
    text-shadow: 0 0 5px black;
    background-color: rgb(0, 0, 0);
    box-shadow: 0 0 5px white, 0 0 10px gold;
}

#create-disabled {
    background-color: grey;
    color: black;
    box-shadow: 0 0 0;
    text-shadow: 0 0 0
}

#create-disabled:active {
   transform: scale(1); 
}

@media (max-width: 1000px) {
    .choose-hall img {
        width: 250px;
    }

    .members-list {
        padding: 0 10px 0 10px;
    }
    .members-list .buttons {     
        width: 150px;   
        margin-left: 150px;
    }
}