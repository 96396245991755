body {
    height: 100%;
    background-color: #04132c;
    opacity: 1;
    background-image:  linear-gradient(30deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000), linear-gradient(150deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000), linear-gradient(30deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000), linear-gradient(150deg, #000000 12%, transparent 12.5%, transparent 87%, #000000 87.5%, #000000), linear-gradient(60deg, #00000077 25%, transparent 25.5%, transparent 75%, #00000077 75%, #00000077), linear-gradient(60deg, #00000077 25%, transparent 25.5%, transparent 75%, #00000077 75%, #00000077);
    background-size: 1px 5px;
    background-position: 0 0, 0 0, 9px 16px, 9px 16px, 0 0, 9px 16px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
}

button {
    transition: all 0.15s linear;
}

button:active {
    transform:scale(1.1) ; /* Move the button down slightly when active */
    transition: all 0.05s linear;
}

.hint-text {
    font-size: 0.8em;
}

@media (max-width: 1000px) {
    .hint-text {
        font-size:0.7em;
    }
}