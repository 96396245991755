@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@300&display=swap');

@import url(https://fonts.googleapis.com/css?family=Cabin:400);

.container
{
  width: fit-content;
  display: flex;
  margin: auto;
  flex-direction: row;
  justify-content: space-evenly;
}
.container .btn {
  position:relative;
  width: 100px;
  height: 30px;
  margin: 20px 5px;
  transition: all 0.5s linear;
}

.container .btn-pressed {
    position:relative;
    width: 100px;
    height: 30px;
    margin: 20px 5px;
    transition: all 0.5s linear;

}
.container .btn a
{
  font-family: 'Cabin', helvetica, arial, sans-serif;
  position:absolute;
  top:0;
  left:0;
  width:100%;
  height:100%;
  display:flex;
  justify-content:center;
  align-items:center;
  background: rgba(255,255,255,0.05);
  box-shadow: 0 15px 35px rgba(0,0,0,0.2);
  border-top: 1px solid rgba(255,255,255,0.1);
  border-bottom:1px solid rgba(255,255,255,0.1);
  border-radius: 30px;
  color: #fff;
  z-index: 1;
  font-size: 400;
  font-weight: 300;
  letter-spacing: 1px;
  text-decoration: none;
  overflow: hidden;
  transition: 0.5s;
  backdrop-filter: blur(15px);
  
}

.container .btn-pressed a {
    font-family: 'Cabin', helvetica, arial, sans-serif;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    background: rgba(255,255,255,0.05);
    box-shadow: 0 15px 35px rgba(0,0,0,0.2);
    border-top: 1px solid rgba(255,255,255,0.1);
    border-bottom:1px solid rgba(255,255,255,0.1);
    border-radius: 30px;
    color: #fff;
    z-index: 1;
    font-size: 400;
    font-weight: 300;
    letter-spacing: 1px;
    text-decoration: none;
    overflow: hidden;
    transition: 0.5s;
    backdrop-filter: blur(15px);
}

.container .btn-pressed {
  cursor: pointer;
}

.container .btn:hover a
{
  cursor:pointer;
  letter-spacing: 3px;
}

.container .btn a::before
{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255,255,255,0.15),
  transparent);
  transform: skewX(45deg) translateX(0);
  transition: 0.5s;
}

.container .btn-pressed a::before
{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: linear-gradient(to left, rgba(255,255,255,0.15),
  transparent);
  transform: skewX(45deg) translateX(0);
  transition: 0.5s;
}




.container .btn:hover a::before
{
  transform: skewX(45deg) translateX(200%);
}
.container .btn::before
{
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
  width:30px;
  height:10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0s;
}
.container .btn-pressed::before
{
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -5px;
  width:30px;
  height:10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0s;
}

.container .btn:hover::before
{
  bottom: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
  transition-delay: 0s;
}

.container .btn-pressed::before {
    bottom: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
    transition-delay: 0s;
}


.container .btn::after
{
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -5px;
  width:30px;
  height:10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0s;
}

.container .btn-pressed::after
{
  content: '';
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -5px;
  width:30px;
  height:10px;
  background: #f00;
  border-radius: 10px;
  transition: 0.5s;
  transition-delay: 0s;
}

.container .btn:hover::after
{
  top: 0;
  height: 50%;
  width: 80%;
  border-radius: 30px;
  transition-delay: 0s;
}

.container .btn-pressed::after {
    top: 0;
    height: 50%;
    width: 80%;
    border-radius: 30px;
    transition-delay: 0s;
}

.container .btn:nth-child(1)::before,
.container .btn:nth-child(1)::after
{
  background: #ff1f71;
  box-shadow: 0 0 5px #ff1f71,
  0 0 15px #ff1f71,
  0 0 30px #ff1f71,
  0 0 60px #ff1f71;
}

.container .btn:nth-child(3)::before,
.container .btn:nth-child(3)::after
{
  background: #2bd2ff;
  box-shadow: 0 0 5px #2bd2ff,
  0 0 15px #2bd2ff,
  0 0 30px #2bd2ff,
  0 0 60px #2bd2ff;
}

.container .btn:nth-child(2)::before,
.container .btn:nth-child(2)::after
{
  background: #1eff45;
  box-shadow: 0 0 5px #1eff45,
  0 0 15px #1eff45,
  0 0 30px #1eff45,
  0 0 60px #1eff45;
}

.container .btn-pressed:nth-child(1)::before,
.container .btn-pressed:nth-child(1)::after
{
  background: #ff1f71;
  box-shadow: 0 0 5px #ff1f71,
  0 0 15px #ff1f71,
  0 0 30px #ff1f71,
  0 0 60px #ff1f71;
}

.container .btn-pressed:nth-child(3)::before,
.container .btn-pressed:nth-child(3)::after
{
  background: #2bd2ff;
  box-shadow: 0 0 5px #2bd2ff,
  0 0 15px #2bd2ff,
  0 0 30px #2bd2ff,
  0 0 60px #2bd2ff;
}

.container .btn-pressed:nth-child(2)::before,
.container .btn-pressed:nth-child(2)::after
{
  background: #1eff45;
  box-shadow: 0 0 5px #1eff45,
  0 0 15px #1eff45,
  0 0 30px #1eff45,
  0 0 60px #1eff45;
}