.about-body {
    margin: 20px auto;
}

.about-box {
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0, 0.568);
    margin: auto;
    margin-top: 100px;
    margin-bottom: 50px;
    transition: all 1s ease-in-out;
    display: inline-block;
    padding: 20px;
    width: 500px;
    height: fit-content;
    text-align: center;
    position: relative;
}
.about-text {
    text-align: center;
}

.socials {
    text-align:center;
}

.socials img {
    display: inline-block;
    width: 30px;
    height: 30px;
    margin: 10px;
    transition: 300ms ease-out;
}

.socials img:hover {
    transition: 500ms ease-out;
    filter: drop-shadow(0 0 10px white);
}

.dev {
    
}

@media (max-width: 1000px) {
    .about-box {
        width: 300px;
    }
}