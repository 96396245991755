.start-text {
    margin: auto;
    text-align: center;
}

@media (max-width: 1000px) {
    .story-div
     {
        font-size: 0.7em;
    }
}