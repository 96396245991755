body {
    background: linear-gradient(253deg, #160000, #0a001b, #001405);
    background-size: 300% 300%;
    -webkit-animation: Background 10s ease-in-out infinite;
    -moz-animation: Background 10s ease-in-out infinite;
    animation: Background 10s ease-in-out infinite;
  }
  
  @-webkit-keyframes Background {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
  
  @-moz-keyframes Background {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
  
  @keyframes Background {
    0% {
      background-position: 0% 50%
    }
    50% {
      background-position: 100% 50%
    }
    100% {
      background-position: 0% 50%
    }
  }
  
  .full-screen {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: url(https://i.imgur.com/wCG2csZ.png);
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
  }
    