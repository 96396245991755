.center {
    margin: 50px auto
}

.center-text {
    text-align: center;
}

#splash {
    display:flex;
    position:relative;
    margin: 50px auto;
    width: 50%;
    border-radius: 150px;
    border: rgb(153, 115, 44) solid;
    border-width: 2px;
    box-shadow: 0 0 10px 5px black, 0 0 50px 10px rgba(255, 255, 255, 0.233);
    transition: all 0.7s ease-in-out;
    offset: auto;
}

#splash:hover {
    width:52%;
    box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.61);

    transition: all 1s ease-in-out;
}

.miner-stats {
    display: flex;
    flex-wrap:wrap;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.466);
    padding: 10px;
    border-radius: 30px;
    border-width: 3px;
    border-color: rgba(255, 255, 255, 0.89);
    border-style: solid;
    width: 200px;
    margin: 50px auto;
    text-align: center;
    transition: all 1s linear;
    animation: 0.5s ease-in-out 0s 1 slideInFromLeft;
    z-index: 100;
}



.miner-stats div {
    margin: 0 50px;
}

.info-header {
    width: 500px;
    margin: auto;
}

.info-divide {
    margin: 50px auto;
    display:flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 800px;
}

.info-divide div {
    width: 350px;
    margin: 0 1px;
}
.info-divide img {
    border-radius:25px;
    box-shadow: 0 0 5px white, 5px 5px 20px black;
    width: inherit;
}

.info-footer {
    height: 200px;
}

@media (max-width: 1000px) {
    h3 {
        font-size: 50px;
    }
    .miner-stats {
        width: 200px;
        border-radius: 100px;
    }
    .miner-stats div {
        margin: 0 20px;
        font-size: 2em;
    }
    .miner-stats div h1 {
        font-size: 0.7em;
    }
    .info-header {
        width: 300px;
    }
    .info-divide {
        display: block;
        width: 250px;
    }

    .info-divide img {
        width: 250px;
    }
    .info-divide div {
        width: 250px;
    }

    span {
        font-size: 0.8em;
    }

    #splash {
        width: 85%;
    }
    #splash:hover {
        width:85%;
        box-shadow: 0 0 10px 5px rgba(255, 255, 255, 0.61);
    
        transition: all 1s ease-in-out;
    }
    
    h1 {
        font-size: 18px;
    }
    h3 {
        font-size: 15px;
    }
}
