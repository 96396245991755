.black-screen {
    position: fixed;
    display: flex;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.356);
    margin: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    animation: 0.5s ease-in-out 0s 1 appear-screen;
    overflow: auto;
}

@keyframes appear-screen {
    0% {
        opacity: 0%;
    }
    100% {
         opacity: 100%;
    }
}

.text {
    animation: 3s linear 0s 1 appear;
}

    @keyframes appear {
        0% {
            opacity: 0%;
        }
        60% {
            opacity: 0%;
        }
        100% {
             opacity: 100%;
        }
    }
.warn-box {
    position: relative;
    text-align: center;
    z-index: 3;
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0);
    margin-left: auto;
    margin-right: auto;
    margin-top: 10%;
    margin-bottom: 50px;
    padding: 10px;
    padding-bottom: 33px;
    width: 350px;
    height: fit-content;
    transition: all 1s ease-in-out;
    display: inline-block;
    border-width: 3px;
    animation: 1.5s ease-in-out 0s 1 warnScale;
    opacity: 100%;
}

.boss-warn-box {
    position: relative;
    text-align: center;
    z-index: 3;
    border-radius: 50px;
    border-style: solid;
    border-color: white;
    background-color: rgba(0, 0, 0);
    margin-left: auto;
    margin-right: auto;
    margin-top: 5%;
    margin-bottom: 50px;
    padding: 10px;
    padding-bottom: 40px;
    width: 350px;
    height: fit-content;
    transition: all 1s ease-in-out;
    display: inline-block;
    border-width: 3px;
    animation: 1.5s ease-in-out 0s 1 warnScale;
    opacity: 100%;
}

.boss-warn-box p {
    font-size: 0.9em;
}

.boss-warn-box .hint-text {
    color: rgba(255, 255, 255, 0.783);
    font-size: 0.8em;
}

.boss-text {
}

.boss-text p {
    font-family: 'Courier New', Courier, monospace;
    color:antiquewhite;
}

.boss-text pre {
    font-size:0.9em;
    white-space: pre-line;
    font-family: 'Courier New', Courier, monospace;
    color:antiquewhite;
}

.boss-text h2 {
    font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 2em;
    animation: bossNameGlow 1000ms infinite ease-in-out
}

.quest-text pre {
    font-size:0.9em;
    white-space: pre-line;
    font-family: 'Courier New', Courier, monospace;
    color:antiquewhite;
}

.quest-img {
    width: 500px;
    border-radius: 50px;
    box-shadow: 0 0 5px white;
}

.boss-img {
    width: 300px;
    border-radius: 50px;
    box-shadow: 0 0 5px white;
}

.video {
    width: 500px;
    border-radius: 50px;
    box-shadow: 0 0 5px white;
}

.boss-item-text h2 {
    font-size: 2em;
    animation: bossNameGlow 1000ms infinite ease-in-out
}

.item-effect h3 {
    line-height: 0px;
}


@media (max-width: 1000px) {
    p {
        font-size: 1em;
    }

    .quest-img {
        width: 300px;
    }
}


@keyframes bossNameGlow {
    0% {
        text-shadow: 0px 0px 20px green
    }
    33% {
        text-shadow: 0px 0px 15px blue 
    }
    66% {
        text-shadow: 0px 0px 20px red
    }
    100% {
        text-shadow: 0 0 20px green;
    }
}

@keyframes warnScale {
    0% {
        opacity: 0%;
        transform: scale(0%);
    }
    100% {
        opacity: 100%;
        transform: scale(100%);
    }
}