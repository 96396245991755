@import url(https://fonts.googleapis.com/css?family=Cabin:400);

.webdesigntuts-workshop div {
	background: #111;
	background: linear-gradient(#09005c, rgb(1, 0, 31));
	border: 1px solid #000;
	border-radius: 5px;
	box-shadow: inset 0 0 10px #141446;
	display: inline-block;
	font-size: 0px;
	margin: auto 0;
	padding: 0px;
	position: relative;
	z-index: 1;
}

.webdesigntuts-workshop input {
	background: #222;	
    transition: all 0.7s linear;
	border: 1px solid #444;
	border-radius: 5px 0 0 5px;
	box-shadow: 0 2px 0 #000;
	color: #888;
	display: block;
	float: left;
	font-family: 'Cabin', helvetica, arial, sans-serif;
	font-size: 13px;
	font-weight: 400;
	height: 38px;
	margin: 0;
	padding: 0 10px;
	text-shadow: 0 -1px 0 #000;
	width: 50px;
    
}

.ie .webdesigntuts-workshop input {
	line-height: 40px;
    
}

.webdesigntuts-workshop input::-webkit-input-placeholder {
   color: #888;
}

.webdesigntuts-workshop input:-moz-placeholder {
   color: #888;
}

.webdesigntuts-workshop input:hover {
    animation: glow 800ms ease-in-out infinite alternate;
	background: #222922;
	background: linear-gradient(#09005c, rgb(1, 0, 31));
	border-color: #393;
	box-shadow: 0 0 5px cyan, inset 0 0 5px blue, 0 2px 0 #000;
	color: #efe;
	outline: none;
    transition: all 1s linear;
}

.webdesigntuts-workshop input:focus {
	animation: glow 800ms ease-in-out infinite alternate;
	background: #222922;
	background: linear-gradient(#09005c, rgb(1, 0, 31));
	border-color: #393;
	box-shadow: 0 0 5px cyan, inset 0 0 5px blue, 0 2px 0 #000;
	color: #efe;
	outline: none;
    transition: all 1s linear;
}

.webdesigntuts-workshop input:focus::-webkit-input-placeholder { 
	color: #efe;
    transition: all 1s linear;

}

.webdesigntuts-workshop input:focus:-moz-placeholder {
	color: #efe;
    transition: all 1s linear;

}

.webdesigntuts-workshop button {
	background: #222;
	box-sizing: border-box;
	border: 1px solid #444;
	border-left-color: #000;
	border-radius: 0 5px 5px 0;
	box-shadow: 0 2px 0 #000;
	color: #fff;
	display: block;
	float: left;
	font-family: 'Cabin', helvetica, arial, sans-serif;
	font-size: 13px;
	font-weight: 400;
	height: 40px;
	line-height: 40px;
	margin: 0;
	padding: 0;
	position: relative;
	text-shadow: 0 -1px 0 #000;
	width: 80px;
    transition: all 0.3s linear;
}	

.webdesigntuts-workshop button:hover,
.webdesigntuts-workshop button:focus {
    transition: all 0.3s linear;
	background: #292929;
	background: linear-gradient(#09005c, rgb(1, 0, 31));
	color: rgb(255, 255, 255);
    text-shadow: 0 0 25px white, 0 0  20px white, 0 0 10px rgb(0, 110, 255);
	outline: none;
}

.webdesigntuts-workshop button:active {
	background: #292929;
	background: linear-gradient(#393939, #292929);
	box-shadow: 0 1px 0 #000, inset 1px 0 1px #222;
	top: 1px;
}

@keyframes glow {
    0% {
		border-color: rgb(0, 110, 255);
		box-shadow: 0 0 10px rgba(0, 67, 94, 0.2), inset 0 0 5px rgba(13, 110, 255, 0.445), 0 2px 0 #000;
    }	
    100% {
		border-color: rgb(255, 48, 227);
		box-shadow: 0 0 20px rgba(255, 172, 255, 0.6), inset 0 0 10px rgba(255, 21, 216, 0.4), 0 2px 0 #000;
    }
}
