.connect-button {
    background-color: black;
    font-family: monospace;
    font-size: 15pt;
    color: white;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin: 20px;
}

.connected {
    background-color: black;
    font-family: monospace;
    font-size: 15pt;
    color: white;
    border-radius: 20px;
    padding-left:10px;
    padding-right: 10px;
    box-shadow: 0 0 20px rgb(255, 255, 255);
    margin: 20px;
}




.connected:hover {
    transition: 0.3s;
    background-color: white;
    color: black;
    border-radius: 20px;
    box-shadow: 0 0 20px rgb(255, 255, 255);
}

.connect-button:hover {
    transition: 0.3s;
    background-color: white;
    color: black;
    border-radius: 20px;
}

.fixed-header {
    position: fixed;
    right: 10px;
    top: 0;
    z-index: 10;
}

@media (max-width: 1000px) {
    .connect-button {
        font-size: 8pt;
        overflow: hidden;
        width: 70px;
        height: 20px;
        margin-right: 1px;
    }
    .connected {
        font-size: 8pt;
        overflow: hidden;
        width: 70px;
        height: 20px;
        margin-right: 1px;
        box-shadow: 0 0 10px rgb(255, 255, 255);
    }
}